import React from "react";
import Layout from "../components/Layout";
import Paper from "../components/Paper";

const Page = () => (
  <Layout fullgreen title="Career">
    <Paper>
      <div className="page-header">
        <h1>Be part of something big</h1>
        <p>Create the future of wholesale</p>
      </div>
      <div className="page-content text-center">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-xxl-8 offset-xxl-2">
            <p>
              We are a fast-moving, fast-growing startup with a proactive
              attitude.
            </p>
            <br />

            <p className="mt-4">
              <a
                href="https://jobs.supplyme.ae/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary btn-lg"
              >
                See open roles
              </a>
            </p>
          </div>
        </div>
      </div>
    </Paper>
  </Layout>
);

export default Page;
